/*============ footer ============*/
footer{
    position: relative;
    .footer-graphic{
        display: block;
        position: absolute;
        height: 100%;
        width: 100%;
        img{
            height: 100%!important;
            width: 100%;
            object-fit: cover;
        }
        &::before{
            content: "";
            display: block;
            position: absolute;
            background-color: rgba(20, 13, 6, 0.87);
            height: 100%;
            width: 100%;
        }
    }
    .footer-bottom {
  
        color:#fff;
        padding:4rem 0;
        .contentwrap{
            display: grid;

            grid-template-areas: "footergov footermenu footerifarm";
 
            grid-gap:2rem;
            .footer-contact{
                grid-area: footermenu;
            }
        
            .footer-content{
                grid-area: footerifarm;
                margin: 0 auto;
                max-width: 280px;
                color: var(--green);
                font-size: clamp(16px, 3vw, 18px);
                padding-bottom: 2rem;
              
            }
            .footer-content-government{
                grid-area: footergov;
                margin: 0 auto;
                // max-width: 280px;
                color: var(--green);
                font-size: clamp(16px, 3vw, 18px);
                padding-bottom: 2rem;
            }
            @media screen and (max-width:1200px) {
                grid-template-areas:
                "footergov footermenu"
                "footerifarm footermenu";
                
                .footer-content, .footer-content-government{
                    max-width: 280px;
                }
            }
            @media screen and (max-width:800px) {
                grid-template-areas:
                "footergov"
                "footermenu"
                "footerifarm";
            }
            @media screen and (max-width:560px) {
                .footer-menu-top ul{
                    flex-direction: column;
                    text-align: center;
                }
                .footer-menu-bottom{
                    text-align: center;
                }
            }
            
        }
        a.logo{
            filter: brightness(0)invert(1);
            margin-bottom: 2rem;
            display: block;
            margin: auto;
            width: 100%;
        }
        .footer-contact{
            margin: 0 auto;
            .depth-1, .depth-0{
                min-width: 170px;
                @media screen and (max-width:500px) {
                    min-width: 0!important;
                }
            }
        }
        .footer-menu-top{
            padding-bottom: 4rem;
            & > ul{
                display: flex;
                gap:2rem 1rem;
                .spacer{
                    height: 20px;
                }
                .depth-0{
                    & > a {
                        margin-bottom: 1.5rem;
                        .title{
                            color: var(--green);
                        }
                    }
                    & > ul{
                        display: flex;
                        flex-direction: column;
                        font-size: clamp(16px, 3vw, 18px);
                        a:hover{
                            color: var(--green);
                        }
                    } 
                }
            }
        }
        .footer-menu-bottom{
            & > ul{
                display: flex;
                justify-content: flex-end;
                gap: 1rem;
                color: var(--green);
                font-size: 14px;
                text-decoration: underline;
            }
        }
    }
    // @media screen and (min-width:998px) {
    //     .footer-contact{
    //         margin-left: 0!important;
    //     }
    //     .footer-content{
    //         margin-right: 0!important;
    //     }
    // }
    // @media screen and (max-width:768px) {
    //     .footer-bottom{
    //         .contentwrap{
    //             justify-content: center;
    //             flex-direction: column-reverse;
    //             text-align: center;
    //             align-items: center;
    //         }
    //         .footer-contact{
    //             margin:auto;
    //         }
    //     }
        
    //     .footer-menu-top{
    //         & > ul{
    //             flex-direction: column;
    //         }
    //     }
    // }
    // @media screen and (max-width:500px) {
    //     .footer-menu-bottom{
    //         & > ul{
    //             flex-direction: column;
    //         }
    //     }
    //     .footer-bottom {
    //         .contentwrap{
    //             padding:0;
    //             margin: 10px;
    //         }
    //         a.logo{
    //             width: 100%;
    //         }
    //     }
    // }
}