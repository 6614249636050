.card{
    position: relative;
    background-blend-mode: multiply;
    width: 100%;
    aspect-ratio: 4/5;
    
    text-decoration: none;
    overflow: hidden;
    color: var(--white);
   
    box-shadow: 0 3px 20px #9A9A9A;
    
    
    .content{
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        text-align: center;
        height: 100%;
        width: 100%;
        padding:2rem 1rem;
        .subtitle{
            font-size: 16px;
        }
        .sub-heading{
            font-weight: 700;
        }
    }
    .page-icon{
        display: block;
        position: absolute;
        top:0;
        bottom:0;
        left:0;
        right: 0;
        z-index: 0;
        &::before{
            content: "";
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: #9A9A9A;
            mix-blend-mode: multiply;
            transition: all .5s ease-in-out;
            z-index: 1;
        }
        img{
            transition: all .5s ease-in-out;
            height: 100%!important;
            width: 100%;
            object-fit: cover;
            z-index: 0;
        }
    }
    &:hover{
        .page-icon{
            img{
                transform: scale(1.25);
            }
            &::before{
            
                background-color: transparent;
             
            }
        }
    }
}
.related-page-wrapper{
    background: linear-gradient(180deg, transparent 25%, #E8E5E5 25%);
    padding-bottom: 4rem;
    .contentwrap{

        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1rem;
        @media screen and (max-width:998px) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media screen and (max-width:768px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media screen and (max-width:500px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }
} 
