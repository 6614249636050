/** page style**/
.pager{
    list-style: none;
    display: flex;
    justify-content: center;
    gap: 1rem;
    
    li{
        border: 1px solid var(--color-primary);
        background-color: transparent;
        a{
            display: block;
            padding:.5rem .75rem;
            color:var(--black);
            text-decoration: none;
        }
        &:hover, &.active{
            background-color: var(--color-secondary);
            a{
                color:var(--color-primary);
            }
        }
    }

    li.disabled{
        cursor:not-allowed;
        pointer-events: none;
        border-color: #ccc;
        span{
            display: block;
            padding:.5rem .75rem;
        }    
    }
}