
/** form generic **/
form {
    width: 100%;
    box-sizing: border-box;
}
form p {
    position: relative;
    margin: 5px 0;
}
label.float{
    height: 27px;
    line-height: 27px;
}
label.error {
    display: block;
    font-size: 12px;
    line-height: 1;
    padding-top: 5px;
}

input,
textarea {
    min-height: 49px;
    text-align: left;
    padding-left: 20px;
    font-family: var(--font-primary);
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 25px;
    border: 1px solid transparent;
    outline: none;
    background-color: var(--white)!important;
    &:focus{
        border-color: #707070;
    }
    &:read-only, &.readyonly {
        border:none!important;
        background-color: #DBD7D7!important;
        pointer-events: none;
        color:var(--brown)!important;
    }
}
textarea {
    min-height: 80px;
    border: 1px solid #707070;
}
.label-wrap{
    position: absolute;
    left:0;
    top:0;
    height: 100%;
    width: 100%;
    display: flex;

    align-items: center;
    pointer-events: none;
    justify-content: flex-end;
    &.top{
        align-items: flex-start;
        padding-top:1rem;
    }
    @media screen and (max-width:500px){
        position: relative;        
    }
}
.form-group.row{
    position: relative;
    display: block!important;
    & > .col-sm-8{
        flex-grow: 1;
    }
    & > .col-sm-2{
        height: 50px;
        width: 100%;
        position: absolute;
        inset: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        pointer-events: none;
        
        label {
            font-size: clamp(16px, 3.2vw, 20px);
            color: var(--black);
            padding-left: 20px;
            transition: all 1s ease;
            margin-right:100%;
            transform: translateX(100%);
            white-space: nowrap;
        }
        @media screen and (max-width:500px){
            position: relative;     
            height: 30px;   
        }
    }
}

.form-group.focused, .form-group.populated {
    label{
        margin-right: 5%!important;
        transform: translateX(0%)!important;
        font-size: 16px!important;
    }
   
}
.js-float-wrap {
    position:relative;
    transition: all 1s ease;
    
    label.float {
        font-size: clamp(16px, 3.2vw, 20px);
        color: var(--black);
        padding-left: 20px;
        max-width: 100%;
        box-sizing: border-box;
        transition: all 1s ease;
        z-index: 1;
        margin-right:50%;
        transform: translateX(50%);
    }
}
.js-float-wrap.focused label.float,
.js-float-wrap.populated label.float {
    margin-right: 5%!important;
    transform: translateX(0%)!important;
    font-size: 16px;
}

form button {
    cursor: pointer;
}

button[type=submit]:disabled{
    cursor:not-allowed;
    background-color: #999;
    border-color: #999;
}

.login-form{
   
    text-align: center;

    .password-reset{
        color:inherit;
        margin-top: 1rem;
        margin-bottom: 2rem;
    }
    .login-btn{
        margin: 1rem 0;      
        pointer-events: none;
        // width: fit-content;
    }
}
.hidden-fields{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap:1rem;
    & > *{
        width: 100%;
    }
    max-height: 0;
    transition: max-height 3s ease, opacity 1.5s ease;
    opacity: 0;
  
    &.active{
        max-height: 1000px;
        opacity: 1;
    }
}