.t-network{
    #map {
        height: 100%;
    }
      
      /* 
       * Optional: Makes the sample page fill the window. 
       */
    .page-body{
        padding-bottom: 1.5rem;
    }
    .map-wrap{
        display: block;
        width:100%;
        box-sizing: border-box;
        padding-bottom:max(60vh, 40vw);
        position: relative;
        background-color: #d3d3d3;
    }
    .kms-loader {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -50px;
        margin-left: -50px;
    }
    #map,
    #map-click {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    #map {
        display: block;
    }
    .marker-wrap{
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding:5px;
        a{
            color:inherit;
            text-decoration: none;
        }
        .marker-title{
            font-size: clamp(20px, 4vw, 36px);
            font-weight: 700;
            color: var(--brown);
            margin:0;
            margin-left: 2rem;
            position: relative;
            &::before{
                content:url("../assets/img/pin-brown.svg");
                width: 15px;
                height: auto;
                left: -32px;
                position: absolute;
            }
        }
        .marker-website{
            font-size: clamp(16px, 3.6vw, 24px);
            color: var(--brown);
            margin:0;
            margin-left: 2rem;
        }
        .marker-email{
            font-size: clamp(16px, 3.6vw, 24px);
            color: var(--green);
            margin:0;
            margin-left: 2rem;
        }
        .marker-name{
            font-size: clamp(16px, 3.6vw, 24px);
            color: var(--green);
            margin:0;
            margin-left: 2rem;
        }
    }
}