/*============ sub page============*/
.t-page{
    .page-graphic {
        padding-top:max(200px, 19.43%);
        background-size:cover;
        background-position:center;
        position:relative;
        display:block;
        img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100% !important;
            object-fit: cover;
        }
    }
    .breadcrumbs{
        display: flex;
        align-items: center;
        gap: clamp(5px, 3vw, 1rem);
        flex-wrap: wrap;
    
        padding-top: 5rem;
        a{
            font-size:clamp(16px, 3.6vw, 24px);
            text-decoration: none;
            color: var(--green);
            &:hover{
                transition: transform .3s ease;
                transform: scale(1.1);
            }
        }
        @media screen and (max-width:500px) {
            
            & > *{
                display: none;
            }
            & > *:nth-last-child(-n+3){
                display: block;
            }
        }
        @media screen and (max-width:300px) {
            display: none;
        }
    }
    .page-title {
        font-size: clamp(24px, 5vw, 60px);
        color: var(--green);
        padding-top: 5rem;
        padding-bottom: 1rem;
        font-weight: 400;
        margin:0 auto;
    }
    .breadcrumbs ~ .page-title{
        padding-top: 2rem;
    }
    .page-body{
        font-size: clamp(16px, 3.4vw, 22px);
    }
}

