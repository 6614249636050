.t-landing{
    .site-content-menu-slide{
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    main{
        flex-grow: 1;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .header-menu{
        display: flex!important;
    }
    .page-bg{
        img{
            display: block;
            position: absolute;
            top:0;
            left:0;
            bottom:0;
            width:100%;
            height: 100% !important;
            object-fit:cover;
            z-index: -1;
        }
        &::after{
            content: "";
            background-color: #9A9A9A;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            display: block;
            mix-blend-mode: multiply;
            z-index: -1;
        }
    }
    .page-content{

        color:var(--white);
        display: flex;
        flex-direction: column;
        align-items: center;
        .summary{
            text-align: center;
            max-width: 400px;
            font-size: clamp(16px, 3.2vw, 20px);
            max-height: 500px;
            opacity: 1;
            transition: max-height .5s ease, opacity 1.5s ease;
            &.hide{
                opacity: 0;
                max-height: 0px;
            }
        }
        .tagline{
            max-width: 600px;
        }
    }
    .js-float-wrap, .form-group > .col-sm-2{
        label{
            margin-right: 50%;
            transform: translateX(50%);
            @media screen and (max-width:500px) {
                color: var(--white);
            }
        }
      
    }
    label.error{
        text-align: left;
        font-weight: 700;
        color: var(--white);
        padding:5px;
        border-left: solid 5px red;
    }
}