.t-profile{
    main{
        // background-color: #E8E5E5;
    }
    .main-wrapper{
        display: grid;
        grid-template-columns: minmax(300px, .5fr) auto;
        gap:2rem;
        padding:2rem 0;
        @media screen and (max-width:998px) {
            grid-template-columns: 1fr;
        }
    }
    .page-title{
        font-size: clamp(22px, 4.5vw, 48px);
        padding-top: 0;
        padding-bottom: 2rem;
        @media screen and (max-width:998px) {
            text-align: center;
        }
    }
    .form-area{
        .result-wrapper{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: clamp(16px, 3.6vw, 24px);
           
            height: 100%;
            #form-result{
                color:var(--green);
                margin-bottom: 0;
                font-weight: 400;
                font-size: clamp(20px, 4vw, 36px);
            }
        }
        form{
            display: flex;
            flex-direction: column;
            gap:1rem;
            .js-float-wrap{
                label.float{
                    margin-right: 100%;
                    transform: translateX(100%);
                    white-space:nowrap;
                }
            }
            input, textarea{
                border-radius: 9px;
                color: var(--brown);
                border: 1px solid #707070;
            }
            textarea{
                min-height: 170px;
                padding-top:1rem;
            } 

         
            .button{
                align-self: flex-end;
                @media screen and (max-width:500px) {
                    width:100%;
                }
            }
        }
      
    }
    .contact-details{
        display: flex;
        flex-direction: column;
        color: #707070;
        width: fit-content;
        @media screen and (max-width:998px) {
            margin: 0 auto;
        }
        .item{
            display: grid;
            grid-template-columns: clamp(90px, 12vw, 120px) auto;

            margin:0;
            padding:0;
            font-size: clamp(16px, 3.4vw, 22px);
            .label{
                font-weight: 700;
                color: var(--brown);
                
            }
        }
    }
    .contact-details .social-links{
        margin-top:2rem;
    }
    .row{
        display: flex;
        gap:1rem;
    }
    .register-title{
        font-size: clamp(22px, 4.5vw, 48px);
        color:var(--green);

    }
}