.t-idea{
    main{
        background-color: #E8E5E5;
    }
    .main-wrapper{
        display: grid;
        grid-template-columns: minmax(300px, .5fr) auto;
        gap:2rem;
        padding:4rem 20px;
        @media screen and (max-width:998px) {
            grid-template-columns: 1fr;
        }
    }
    .summary{
        color:var(--green);
        font-size: clamp(16px, 3.6vw, 24px);
    }
    .page-title{
        font-size: clamp(22px, 4.5vw, 48px);
        padding-top: 2rem;
    }
    .form-area{
        .result-wrapper{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: clamp(16px, 3.6vw, 24px);
           
            height: 100%;
            #form-result{
                color:var(--green);
                margin-bottom: 0;
                font-weight: 400;
                font-size: clamp(20px, 4vw, 36px);
            }
        }
        form{
            display: flex;
            flex-direction: column;
            gap:1rem;
            .js-float-wrap{
                label.float{
                    margin-right: 100%;
                    transform: translateX(100%);
                    white-space:nowrap;
                }
            }
            input, textarea{
                border-radius: 9px;
            }
            textarea{
                min-height: 170px;
                padding-top:1rem;
            }

            .file-upload-container{
                display: flex;
                gap:1rem;
                align-items: center;
                padding:0 1rem;
                background: var(--white);
                border-radius: 9px;
                min-height: 54px;
                border:1px solid #707070;
                font-size: clamp(16px, 3.2vw, 20px);
                .file-browse{
                    margin-left:auto;
                }
                a{
                    text-decoration: none;
                    color: var(--green);
                    font-size: clamp(16px, 3.2vw, 20px);
                }
                .file-upload-info{
                    order:-1
                }
            }
            .button{
                align-self: flex-end;
                @media screen and (max-width:500px) {
                    width:100%;
                }
            }
        }
        .upload-section{
            font-size: clamp(16px, 3.2vw, 20px);
            color:#3D3D3D;
        }
    }
}