/*============ header ============*/
header.top {
    font-family: var(--font-primary);

}
.header-bar-top {
    // color:#ffffff;
    background: #ffffff;
    background-size:cover;
    border-bottom:1px solid var(--brown);
}

header.top ul {
    font-size: clamp(16px, 3vw, 18px);
    color:var(--brown);

}
header.top li {
    vertical-align:top;
}
.header-menu {
    padding:0 2rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
  
    .government-logo{
        width: 170px;
        padding:1rem;
        // padding-right: 2rem;
        margin-right:auto;
    }
    .menu-logo-wrapper{
        display: flex;
        flex-wrap: wrap-reverse;
    }
    a.logo{
        width: 200px;
        padding:1.5rem 0;
        padding-left: 2rem;
    }
    .menu-wrapper{
        margin-top: auto;
        margin-left: auto;
        display: flex;
        align-items: center;
        ul {
            margin:0;
            padding:0;
            text-align:right;
            .depth-0{ 
                a{
                    border-bottom:10px solid transparent;
                    &:hover{
                        color: var(--green);
                    }
                }
                
                &.active, &.sub-active{
                    a{
                        border-color:var(--brown);
                    }
                }
                ul{
                    display: none;
                }
            }
        }
        .btn-logout{
            margin-bottom: 10px;
            margin-left:10px;
        }
    }
    @media screen and (max-width:1600px) {
        .menu-wrapper{
            order: 3;
            width: 100%;
            justify-content: flex-end;
        }
    }
    @media screen and (max-width: 960px){
        a.logo{
            margin-right: 2rem;
           
        }
    }
    @media screen and (max-width: 500px){
        flex-wrap: nowrap;
        justify-content: flex-start;
        a.logo{
            // margin:0;
            padding:1rem;
        }
     
        a.logo, .government-logo{
            width: 30vw;
            padding:10px;
            margin:0;
        }
    }
    @media screen and (max-width: 300px){
        padding:10px;
        gap:10px;
        a.logo, .government-logo{
            width: 25vw;
            padding:0px;
        }
    }
}

.header-menu li {
    a{
        padding: 20px;
    }
}

.main-menu ul {
    text-align:justify;
    line-height:0;
}
.main-menu ul::after {
    content:"";
    display:inline-block;
    width:100%;
    line-height:0px;
    vertical-align:top;
}
.main-menu li {
    margin:5px;
    line-height:1;
}
.main-menu li.depth-0 {
    vertical-align:middle;
}

.main-menu a {
    padding:15px 0;
}
.main-menu li > ul {
    position:absolute;
    top: 100%;
    left:0;
    right:0;
    background:#f6f6f6;
    color:#082133;
    z-index:10000;
    text-align: left;
    display:none;
}
.main-menu li:hover > ul {
    display:block;
}
.main-menu li > ul::before,
.main-menu li > ul::after {
    content:"";
    background:#f6f6f6;
    position:absolute;
    top:0;
    bottom:0;
    width:0;
    height:100%;
}
.main-menu li > ul::before {
    left:0;
    padding-left:50vw;
    margin-left:-50vw;
    background:#f6f6f6;
}
.main-menu li > ul::after {
    right:0;
    padding-right:50vw;
    margin-right:-50vw;
    background:#f6f6f6;
}
.main-menu .depth-1 .title {
    font-size:18px;
}
.main-menu .depth-1 a {
    width: 160px;
    margin: 30px;
}